import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ValidationImage from "./components/ValidationImage.js/index.js";
import ValidationsInfo from "./components/ParcoursInfo/index.js";
import AllActivitesCard from "./components/AllActivitesCard/index.js";
import crudsServiceFAPI from "services/cruds-serviceFAPI";
import { AbilityContext } from "Can";
import { AuthContext } from "context";
import useStore from "services/store";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function AllActivites() {
  const { userUid, userIdentite, userEtab, userRights, role } = useContext(AuthContext);
  const { id } = useParams();
  const { userId } = useStore((state) => state);
  
  console.log("BronzageuserUid : ", userUid, role);
  const ability = useContext(AbilityContext);

  // États pour les images
  const [imageUrl, setImageUrl] = useState("");
  const [isReady, setIsReady] = useState(false);

  // États pour les activités et les référentiels
  const [cardData, setCardData] = useState([]);
  const [rawCardData, setRawCardData] = useState([]); // Données brutes
  const [expandedCardId, setExpandedCardId] = useState(null);
  const [loading, setLoading] = useState(false); // État pour le chargement
  const [selectedReferentiel, setSelectedReferentiel] = useState(""); // Gestion locale via useState
  const [referentiels, setReferentiels] = useState([]);
  const [competences, setCompetences] = useState([]);
  const [selectedCompetence, setSelectedCompetence] = useState(""); // Nouvel état pour la compétence sélectionnée
  const matiereid = useStore((state) => state.selectedMatiere) || {};

  // Fonction pour vérifier l'existence de l'image
  const checkImageExistence = async (url) => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      return response.ok;
    } catch (error) {
      console.error(`Erreur lors de la vérification de l'image à l'URL ${url} :`, error);
      return false;
    }
  };

  // Récupération de selectedPromotion depuis le store
  const { selectedPromotion } = useStore((state) => ({ selectedPromotion: state.selectedPromotion }));

  // Premier useEffect pour vérifier l'image basée sur l'ID
  useEffect(() => {
    if (id) {
      const timestamp = new Date().getTime(); // Cache busting en utilisant un timestamp unique
      const imagePath = `${process.env.REACT_APP_FAPI_URL}/files/activites/${id}.jpg?t=${timestamp}`;

      // Vérifier si l'image existe
      fetch(imagePath)
        .then((response) => {
          if (response.ok) {
            setImageUrl(imagePath);
            console.log("Image trouvée :", imagePath);
          } else {
            // Si l'image n'existe pas, utiliser une image par défaut
            const defaultImage = `${process.env.REACT_APP_FAPI_URL}/files/activites/defaut.jpg?t=${timestamp}`;
            setImageUrl(defaultImage);
            console.log("Image par défaut utilisée :", defaultImage);
          }
          setIsReady(true);
        })
        .catch(() => {
          // En cas d'erreur, utiliser une image par défaut
          const defaultImage = `${process.env.REACT_APP_FAPI_URL}/files/activites/defaut.jpg?t=${timestamp}`;
          setImageUrl(defaultImage);
          setIsReady(true);
          console.log("Erreur lors de la récupération de l'image, image par défaut utilisée.");
        });
    }
  }, [id]);

  // useEffect pour récupérer les référentiels au montage du composant
  useEffect(() => {
    const fetchReferentiels = async () => {
      try {
        console.log("selectedPromotion.identifiant_promotion : ", selectedPromotion.identifiant_promotion);
        const response = await crudsServiceFAPI.getReferentielsParPromotion(selectedPromotion.identifiant_promotion);
        console.log("Référentiels récupérés : ", response);
        setReferentiels(response);

        // Si au moins un référentiel est récupéré, définir le premier automatiquement
        if (response.length > 0) {
          setSelectedReferentiel(response[0].identifiant_referentiel);
          console.log("Référentiel sélectionné automatiquement : ", response[0].identifiant_referentiel);
        } else {
          console.warn("Aucun référentiel trouvé pour la promotion sélectionnée.");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des référentiels :", error);
      }
    };

    // Vérifiez que selectedPromotion est défini avant de faire l'appel API
    if (selectedPromotion && selectedPromotion.identifiant_promotion) {
      fetchReferentiels();
    } else {
      console.warn("selectedPromotion ou identifiant_promotion non défini.");
    }
  }, [selectedPromotion]);

  // useEffect pour récupérer les compétences lorsque un référentiel est sélectionné
  useEffect(() => {
    const fetchCompetences = async () => {
      if (selectedReferentiel) {
        console.log("selectedReferentiel : ", selectedReferentiel);

        try {
          const response = await crudsServiceFAPI.getCompetencesByReferentiel(selectedReferentiel);
          console.log("Compétences récupérées : ", response);
          setCompetences(response);
        } catch (error) {
          console.error("Erreur lors de la récupération des compétences :", error);
        }

      } else {
        setCompetences([]);
      }
    }

    fetchCompetences();
  }, [selectedReferentiel]);

  // Nouvelle useEffect pour récupérer les activités lorsque selectedReferentiel est défini
  useEffect(() => {
    const fetchActivites = async () => {
      if (selectedReferentiel) {
        setLoading(true);
        try {
          const activities = await crudsServiceFAPI.getActivitesByReferentiel(selectedReferentiel);
          console.log("Activités récupérées :", activities);
          setRawCardData(activities);
        } catch (error) {
          console.error("Erreur lors de la récupération des activités :", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchActivites();
  }, [selectedReferentiel]);

  // useEffect pour formater les activités une fois rawCardData mis à jour
  useEffect(() => {
    const formatActivities = async () => {
      const timestamp = new Date().getTime(); // Ajout de timestamp ici
      console.log("rawCardData.length : ", rawCardData.length);
      console.log("selectedCompetence : ", selectedCompetence);

      // Filtrer les activités en fonction de la compétence sélectionnée
      const filteredRawCardData = selectedCompetence
        ? rawCardData.filter(activity =>
            activity.competences.some(competence => competence.identifiant_competence === selectedCompetence)
          )
        : rawCardData;

      console.log("filteredRawCardData.length : ", filteredRawCardData.length);

      const formattedActivities = await Promise.all(
        filteredRawCardData.map(async (activity) => {
          console.log("Traitement de l'activité :", activity.identifiant_activite);
          const imageUrl = `${process.env.REACT_APP_FAPI_URL}/files/activites/${activity.identifiant_parcours}.jpg`;
          const defaultImageUrl = `${process.env.REACT_APP_FAPI_URL}/files/activites/defaut.jpg?t=${timestamp}`;
          const imageExists = await checkImageExistence(imageUrl);

          return {
            titre: activity.titre || "Titre non fourni",
            image: imageExists ? imageUrl : defaultImageUrl,
            description: activity.description || "Description non fournie",
            details: activity.details || "Détails non fournis",
            evaluation: activity.evaluation || "Évaluation non fournie",
            livrables: activity.livrables || "Livrables non fournis",
            todo: activity.todo || "Tâches non fournies",
            competences: activity.competences || [],
            identifiant_activite: activity.identifiant_activite,
            role: role,
          };
        })
      );

      console.log("Activités formatéess4 :", formattedActivities);
      setCardData(formattedActivities);
    };

    if (rawCardData.length > 0) {
      formatActivities();
    } else {
      console.log("Aucune activité à formater.");
    }
  }, [rawCardData, selectedCompetence, role]);

  // Fonction pour gérer la sélection d'une compétence
  const handleSelectCompetence = (identifiantCompetence) => {
    setSelectedCompetence(identifiantCompetence);
    console.log("Compétence sélectionnée : ", identifiantCompetence);
  };

  // Fonction pour gérer l'expansion des cartes
  const handleExpandCard = (identifiant_activite) => {
    setExpandedCardId(expandedCardId === identifiant_activite ? null : identifiant_activite);
  };

  // Fonction pour gérer la sélection d'un référentiel via dropdown
  const handleSelectReferentiel = (identifiantReferentiel) => {
    setSelectedReferentiel(identifiantReferentiel);
    console.log("Référentiel sélectionné via dropdown : ", identifiantReferentiel);
  };

  // Choisir le composant de carte basé sur le rôle de l'utilisateur
  const CardComponent = AllActivitesCard;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Card sx={{ overflow: "visible" }}>
          <MDBox p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} xl={5}>
                <ValidationImage />
              </Grid>
              <Grid item xs={12} lg={5} sx={{ mx: "auto" }}>
                <ValidationsInfo />
              </Grid>
              <Grid item xs={12}>
                {/* Menu déroulant pour sélectionner un référentiel */}
                <MDBox display="flex" alignItems="center" justifyContent="center" mb={3}>
                  <Select
                    value={selectedReferentiel}
                    onChange={(e) => handleSelectReferentiel(e.target.value)}
                    displayEmpty
                    fullWidth
                  >
                    <MenuItem value="" disabled>
                      Sélectionnez un référentiel
                    </MenuItem>
                    {referentiels.map((ref) => (
                      <MenuItem key={ref.identifiant_referentiel} value={ref.identifiant_referentiel}>
                        {ref.denomination_referentiel}
                      </MenuItem>
                    ))}
                  </Select>
                </MDBox>
              </Grid>
              {/* Menu déroulant pour sélectionner une compétence */}
              {selectedReferentiel && competences.length > 0 && (
                <Grid item xs={12}>
                  <MDBox display="flex" alignItems="center" justifyContent="center" mb={3}>
                    <Select
                      value={selectedCompetence}
                      onChange={(e) => handleSelectCompetence(e.target.value)}
                      displayEmpty
                      fullWidth
                    >
                      <MenuItem value="">
                        Toutes les compétences
                      </MenuItem>
                      {competences.map((comp) => (
                        <MenuItem key={comp.identifiant_competence} value={comp.identifiant_competence}>
                          {comp.denomination_competence}
                        </MenuItem>
                      ))}
                    </Select>
                  </MDBox>
                </Grid>
              )}
            </Grid>
          </MDBox>
        </Card>
      </MDBox>

      {/* Affichage basé sur le statut de chargement */}
      {loading ? (
        <MDTypography variant="h6" align="center">
          Chargement des activités...
        </MDTypography>
      ) : (
        <>
          {/* Section pour les vérifications en cours */}
          <MDBox flex="1" mt={2}>
            <Grid container spacing={3}>
              {cardData.map((card) => (
                <Grid item xs={12} sm={6} md={expandedCardId === card.identifiant_activite ? 12 : 4} key={card.identifiant_activite}>
                  <MDBox mt={3}>
                    <CardComponent
                      titre={card.titre}
                      image={card.image}
                      description={card.description}
                      details={card.details}
                      evaluation={card.evaluation}
                      livrables={card.livrables}
                      todo={card.todo}
                      onExpand={() => handleExpandCard(card.identifiant_activite)}
                      expanded={expandedCardId === card.identifiant_activite}
                      competencesTab={card.competences}
                      role={card.role}
                      identifiant_activite={card.identifiant_activite}
                    />
                  </MDBox>
                </Grid>
              ))}
            </Grid>
          </MDBox>
        </>
      )}

      <Footer />
    </DashboardLayout>
  );
}

export default AllActivites;
