import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ValidationImage from "./components/ValidationImage.js/index.js";
import ValidationsInfo from "./components/ParcoursInfo/index.js";
import AllActivitesCard from "./components/AllActivitesCard/index.js";
import crudsServiceFAPI from "services/cruds-serviceFAPI";
import { AbilityContext } from "Can";
import { AuthContext } from "context";
import useStore from "services/store";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function StorePage() {
  const { userUid, userIdentite, userEtab, userRights, role } = useContext(AuthContext);
  const { id } = useParams();
  const { userId } = useStore((state) => state);
  
  console.log("BronzageuserUid : ", userUid, role);
  const ability = useContext(AbilityContext);

  // États pour les images du premier useEffect
  const [imageUrl, setImageUrl] = useState("");
  const [isReady, setIsReady] = useState(false);

  // États pour les activités et les référentiels
  const [cardData, setCardData] = useState([]);
  const [rawCardData, setRawCardData] = useState([]); // Données brutes
  const [expandedCardId, setExpandedCardId] = useState(null);
  const [loading, setLoading] = useState(false); // État pour le chargement
  const [selectedReferentiel, setSelectedReferentiel] = useState("");
  const [referentiels, setReferentiels] = useState([]);
  const matiereid = useStore((state) => state.selectedMatiere) || {};

  // Définition de la fonction checkImageExistence
  const checkImageExistence = async (url) => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      return response.ok;
    } catch (error) {
      console.error(`Erreur lors de la vérification de l'image à l'URL ${url} :`, error);
      return false;
    }
  };

  // Premier useEffect pour vérifier l'image basée sur l'ID
  useEffect(() => {
    if (id) {
      const timestamp = new Date().getTime(); // Cache busting en utilisant un timestamp unique
      const imagePath = `${process.env.REACT_APP_FAPI_URL}/files/activites/${id}.jpg?t=${timestamp}`;

      // Vérifier si l'image existe
      fetch(imagePath)
        .then((response) => {
          if (response.ok) {
            setImageUrl(imagePath);
          } else {
            // Si l'image n'existe pas, utiliser une image par défaut
            setImageUrl(`${process.env.REACT_APP_FAPI_URL}/files/activites/defaut.jpg?t=${timestamp}`);
          }
          setIsReady(true);
        })
        .catch(() => {
          // En cas d'erreur, utiliser une image par défaut
          setImageUrl(`${process.env.REACT_APP_FAPI_URL}/files/activites/defaut.jpg?t=${timestamp}`);
          setIsReady(true);
        });
    }
  }, [id]);

  // Fonction pour gérer la sélection d'un référentiel
  const handleSelectReferentiel = async (identifiantReferentiel) => {
    setSelectedReferentiel(identifiantReferentiel);
    setLoading(true);

    try {
      const activities = await crudsServiceFAPI.getActivitesByReferentiel(identifiantReferentiel); // Fonction qui récupère les activités par référentiel
      console.log("Activités récupérées :", activities);

      // Filtrer les activités où is_evaluation est égal à 0
      const filteredActivities = activities.filter(activity => activity.is_evaluation === 0);
      console.log("Activités filtrées :", filteredActivities);

      setRawCardData(filteredActivities); // Utiliser rawCardData pour stocker les données filtrées
    } catch (error) {
      console.error("Erreur lors de la récupération des activités :", error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect pour récupérer les référentiels au montage du composant
  useEffect(() => {
    const fetchReferentiels = async () => {
      try {
        const response = await crudsServiceFAPI.getAllReferentiels();
        console.log("Référentiels récupérés : ", response);
        setReferentiels(response);
      } catch (error) {
        console.error("Erreur lors de la récupération des référentiels :", error);
      }
    };

    fetchReferentiels();
  }, []);

  // useEffect pour formater les activités une fois rawCardData mis à jour
  useEffect(() => {
    const fetchActivites = async () => {
      const timestamp = new Date().getTime(); // Ajout de timestamp ici
      console.log("rawCardData.length : ", rawCardData);
      const formattedActivities = await Promise.all(
        rawCardData.map(async (activity) => {
          console.log("mettres : ", activity)
          const imageUrl = `${process.env.REACT_APP_FAPI_URL}/files/activites/${activity.identifiant_parcours}.jpg`;
          const defaultImageUrl = `${process.env.REACT_APP_FAPI_URL}/files/activites/defaut.jpg?t=${timestamp}`;
          const imageExists = await checkImageExistence(imageUrl);
          
          return {
            titre: activity.titre || "Titre non fourni",
            image: imageExists ? imageUrl : defaultImageUrl,
            description: activity.description || "Description non fournie",
            details: activity.details || "Détails non fournis",
            evaluation: activity.evaluation || "Évaluation non fournie",
            livrables: activity.livrables || "Livrables non fournis",
            todo: activity.todo || "Tâches non fournies",
            competences: activity.competences || [],
            identifiant_activite: activity.identifiant_activite,
            role: role,
            is_evaluation: activity.is_evaluation
          };
        })
      );
      console.log("Activités formatées :", formattedActivities);
      setCardData(formattedActivities);
    };
    console.log("rawCardData.length : ", rawCardData.length);
    if (selectedReferentiel && rawCardData.length > 0) {
      fetchActivites();
    } else {
      console.log("Attente de la disponibilité de toutes les données...");
    }
  }, [rawCardData, selectedReferentiel, role]);

  // Fonction pour gérer l'expansion des cartes
  const handleExpandCard = (identifiant_activite) => {
    setExpandedCardId(expandedCardId === identifiant_activite ? null : identifiant_activite);
  };

  // Choisir le composant de carte basé sur le rôle de l'utilisateur
  const CardComponent = AllActivitesCard;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Card sx={{ overflow: "visible" }}>
          <MDBox p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} xl={5}>
                <ValidationImage />
              </Grid>
              <Grid item xs={12} lg={5} sx={{ mx: "auto" }}>
                <ValidationsInfo />
              </Grid>
              <Grid item xs={12}>
                {/* Menu déroulant pour sélectionner un référentiel */}
                <MDBox display="flex" alignItems="center" justifyContent="center" mb={3}>
                  <Select
                    value={selectedReferentiel}
                    onChange={(e) => handleSelectReferentiel(e.target.value)}
                    displayEmpty
                    fullWidth
                  >
                    <MenuItem value="" disabled>
                      Sélectionnez un référentiel
                    </MenuItem>
                    {referentiels.map((ref) => (
                      <MenuItem key={ref.identifiant_referentiel} value={ref.identifiant_referentiel}>
                        {ref.denomination_referentiel}
                      </MenuItem>
                    ))}
                  </Select>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>

      {/* Affichage basé sur le statut de chargement */}
      {loading ? (
        <MDTypography variant="h6" align="center">
          Chargement des activités...
        </MDTypography>
      ) : (
        <>
          {/* Section pour les vérifications en cours */}
          <MDBox flex="1" mt={2}>
            <Grid container spacing={3}>
              {cardData.map((card, index) => {
                const isExpanded = expandedCardId === card.identifiant_activite;

                return (
                  <Grid item xs={12} sm={6} md={isExpanded ? 12 : 4} key={card.identifiant_activite}>
                    <MDBox mt={3}>
                      <CardComponent
                        titre={card.titre}
                        image={card.image}
                        description={card.description}
                        details={card.details}
                        evaluation={card.evaluation}
                        livrables={card.livrables}
                        todo={card.todo}
                        onExpand={() => handleExpandCard(card.identifiant_activite)}
                        expanded={isExpanded}
                        competencesTab={card.competences}
                        role={card.role}
                        identifiant_activite={card.identifiant_activite}
                      />
                    </MDBox>
                  </Grid>
                );
              })}
            </Grid>
          </MDBox>
        </>
      )}

      <Footer />
    </DashboardLayout>
  );
}

export default StorePage;
