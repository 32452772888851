import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import FormField from "layouts/parcours/parcours-gestion/parcours-nouveau/components/FormField";
import crudsServiceFAPI from "services/cruds-serviceFAPI";

function ParcoursInfo({ identifiant_parcours, matiere, navigate }) {
  console.log("identifiant_parcours", identifiant_parcours, matiere);  // Log pour vérifier l'ID du parcours
  const [identifiantParcours, setIdentifiantParcours] = useState("");
  const [denominationParcours, setDenominationParcours] = useState("");
  const [descriptionParcours, setDescriptionParcours] = useState("");
  const [identifiantMatiere, setIdentifiantMatiere] = useState(""); // État pour l'identifiant de la matière
  const [padletUrl, setPadletUrl] = useState(""); // État pour le lien Padlet

  useEffect(() => {
    console.log("matiere initial:", matiere);
    if (matiere) {
      console.log("Identifiant de la matière récupéré:", matiere);
      setIdentifiantMatiere(matiere);
    } else {
      console.error("Aucune matière sélectionnée trouvée dans secureLocalStorage");
    }

    const fetchParcoursDetails = async () => {
      if (identifiant_parcours) {
        try {
          const parcoursData = await crudsServiceFAPI.getParcoursById(identifiant_parcours);
          console.log("Détails du parcours récupérés:", parcoursData); // Log pour vérifier les détails du parcours récupérés
          setIdentifiantParcours(parcoursData.identifiant_parcours);
          setDenominationParcours(parcoursData.titre_parcours);
          setDescriptionParcours(parcoursData.description_parcours);
          setPadletUrl(parcoursData.padlet_url || ""); // Initialiser le lien Padlet
        } catch (error) {
          console.error("Erreur lors de la récupération des détails du parcours :", error);
        }
      }
    };

    fetchParcoursDetails();
  }, [identifiant_parcours, matiere]);

  const handleSave = async () => {
    console.log("Identifiant discipline:", identifiantMatiere);
    try {
      const parcoursData = {
        identifiant_parcours: identifiantParcours,
        titre_parcours: denominationParcours,
        description_parcours: descriptionParcours,
        identifiant_matiere: matiere, // Utiliser l'identifiant de la matière récupéré
        padlet_url: padletUrl.trim() !== "" ? padletUrl : null, // Assignation conditionnelle
      };
  
      console.log("Données du parcours à sauvegarder:", parcoursData); // Log pour vérifier les données envoyées
  
      let response;
      if (identifiant_parcours) {
        response = await crudsServiceFAPI.updateParcours(identifiant_parcours, parcoursData); // Mettre à jour un parcours existant
        console.log("Réponse de la mise à jour du parcours:", response); // Log pour vérifier la réponse après mise à jour
      } else {
        response = await crudsServiceFAPI.createParcours(parcoursData); // Créer un nouveau parcours
        console.log("Réponse de la création du parcours:", response); // Log pour vérifier la réponse après création
      }
  
      navigate("/parcours/parcours-espace/parcours-grille");
    } catch (error) {
      console.error("Erreur lors de la sauvegarde du parcours :", error);
    }
  };
  

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">
          {identifiant_parcours ? "Modifier le Parcours" : "Créer un Nouveau Parcours"}
        </MDTypography>

        {/* Dénomination du Parcours */}
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormField
                type="text"
                label="Dénomination du Parcours"
                value={denominationParcours}
                onChange={(e) => setDenominationParcours(e.target.value)}
              />
            </Grid>
          </Grid>
        </MDBox>

        {/* Description du Parcours */}
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Description du Parcours&nbsp;&nbsp;
                  <MDTypography variant="caption" fontWeight="regular" color="text">
                    (facultatif)
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDEditor value={descriptionParcours} onChange={setDescriptionParcours} />
            </Grid>
          </Grid>
        </MDBox>

        {/* Lien Padlet */}
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormField
                type="text"
                label="Lien Padlet"
                value={padletUrl}
                onChange={(e) => setPadletUrl(e.target.value)}
                placeholder="Entrez le lien du Padlet"
              />
            </Grid>
          </Grid>

          {/* Affichage de l'embed Padlet si l'URL est présente */}
          {padletUrl && (
            <MDBox mt={2}>
              <div
                className="padlet-embed"
                style={{
                  border: "1px solid rgba(0,0,0,0.1)",
                  borderRadius: "2px",
                  boxSizing: "border-box",
                  overflow: "hidden",
                  position: "relative",
                  width: "100%",
                  background: "#F4F4F4",
                }}
              >
                <p style={{ padding: 0, margin: 0 }}>
                  <iframe
                    src={padletUrl}
                    frameBorder="0"
                    allow="camera;microphone;geolocation"
                    style={{
                      width: "100%",
                      height: "608px",
                      display: "block",
                      padding: 0,
                      margin: 0,
                    }}
                    title="Padlet Embedded"
                  ></iframe>
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    margin: 0,
                    height: "28px",
                  }}
                >
                  <a
                    href="https://padlet.com?ref=embed"
                    style={{
                      display: "block",
                      flexGrow: 0,
                      margin: 0,
                      border: "none",
                      padding: 0,
                      textDecoration: "none",
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src="https://padlet.net/embeds/made_with_padlet_2022.png"
                        width="114"
                        height="28"
                        style={{
                          padding: 0,
                          margin: 0,
                          background: "0 0",
                          border: "none",
                          boxShadow: "none",
                        }}
                        alt="Made with Padlet"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </MDBox>
          )}
        </MDBox>

        {/* Bouton de sauvegarde */}
        <MDBox mt={2}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            {identifiant_parcours ? "Enregistrer les modifications" : "Créer"}
          </Button>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ParcoursInfo;
