// Evaluations.js

import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ValidationImage from "./components/ValidationImage.js/index.js";
import ValidationsInfo from "./components/ParcoursInfo/index.js";
import AllActivitesCard from "./components/AllActivitesCard/index.js";
import AllEvaluationCard from "./components/AllEvaluationsCard/index.js";
import crudsServiceFAPI from "services/cruds-serviceFAPI";
import { AbilityContext } from "Can";
import { AuthContext } from "context";
import useStore from "services/store";
import DataTable from "examples/Tables/DataTable";
import { IconButton, Backdrop, CircularProgress } from "@mui/material"; // Importation du Backdrop et CircularProgress pour le spinner
import CheckIcon from '@mui/icons-material/Check';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import MDAlert from "components/MDAlert"; // Assurez-vous que ce composant existe
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import TextField from "@mui/material/TextField";
import AddLinkIcon from '@mui/icons-material/AddLink';

function Evaluations() {
  // Contextes et paramètres
  const { userUid, userIdentite, userEtab, userRights, role } = useContext(AuthContext);
  const { id } = useParams();
  const { userId, selectedPromotion, selectedMatiere } = useStore((state) => ({
    userId: state.userId,
    selectedPromotion: state.selectedPromotion,
    selectedMatiere: state.selectedMatiere,
  }));
  console.log("BronzageuserUid : ", userUid, role);
  const ability = useContext(AbilityContext);

  // États pour les images
  const [imageUrl, setImageUrl] = useState("");
  const [isReady, setIsReady] = useState(false);

  // États pour les activités
  const [cardData, setCardData] = useState([]);
  const [rawCardData, setRawCardData] = useState([]); // Données brutes
  const [cardDataIsEval, setCardDataIsEval] = useState([]);
  const [rawCardDataIsEval, setRawCardDataIsEval] = useState([]); // Données brutes
  const [expandedCardId, setExpandedCardId] = useState(null);
  const [loading, setLoading] = useState(false); // État pour le chargement général
  const [spinnerOpen, setSpinnerOpen] = useState(false); // État pour le spinner
  const [popupOpen, setPopupOpen] = useState(false);
  const [formData, setFormData] = useState({
    identifiantParcours: "",
    identifiantEleve: "",
    identifiantActivite: "",
    identifiantEvaluation: "",
  });

  // États pour les évaluations
  const [allEvaluations, setAllEvaluations] = useState([]);
  const [eleves, setEleves] = useState([]);
  const [notification, setNotification] = useState({ value: false, text: "" });

  // Fonction pour vérifier l'existence de l'image
  const checkImageExistence = async (url) => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      return response.ok;
    } catch (error) {
      console.error(`Erreur lors de la vérification de l'image à l'URL ${url} :`, error);
      return false;
    }
  };

  // Premier useEffect pour vérifier l'image basée sur l'ID
  useEffect(() => {
    console.log("noupiiii : ");
    if (id) {
      const timestamp = new Date().getTime(); // Cache busting en utilisant un timestamp unique
      const imagePath = `${process.env.REACT_APP_FAPI_URL}/files/activites/${id}.jpg?t=${timestamp}`;

      // Vérifier si l'image existe
      fetch(imagePath)
        .then((response) => {
          if (response.ok) {
            setImageUrl(imagePath);
            console.log("Image trouvée :", imagePath);
          } else {
            // Si l'image n'existe pas, utiliser une image par défaut
            const defaultImage = `${process.env.REACT_APP_FAPI_URL}/files/activites/defaut.jpg?t=${timestamp}`;
            setImageUrl(defaultImage);
            console.log("Image par défaut utilisée :", defaultImage);
          }
          setIsReady(true);
        })
        .catch(() => {
          // En cas d'erreur, utiliser une image par défaut
          const defaultImage = `${process.env.REACT_APP_FAPI_URL}/files/activites/defaut.jpg?t=${timestamp}`;
          setImageUrl(defaultImage);
          setIsReady(true);
          console.log("Erreur lors de la récupération de l'image, image par défaut utilisée.");
        });
    }
  }, [id]);

  const handleOpenPopup = (row) => {
    setFormData({
      identifiantParcours: row.identifiant_parcours,
      identifiantEleve: row.identifiant_eleve,
      identifiantActivite: "",
      identifiantEvaluation: row.identifiant_evaluation,
    });
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
    setFormData({
      identifiantParcours: "",
      identifiantEleve: "",
      titreParcours: "",
      identifiantActivite: "",
    });
  };

  // Gestion de la sélection dans la liste déroulante
  const handleSelectChange = (event) => {
    setFormData({ ...formData, identifiantActivite: event.target.value });
  };

  // Fonction de validation
  const handleValidation = async () => {
    try {
      // Transmettre uniquement les champs nécessaires
    const payload = {
      identifiantParcours: formData.identifiantParcours,
      identifiantEleve: formData.identifiantEleve,
      identifiantActivite: formData.identifiantActivite,
      identifiantEvaluation: formData.identifiantEvaluation,
    };
      setSpinnerOpen(true); // Ouvrir le spinner

      // Mise à jour de l'évaluation (supposant que vous souhaitez supprimer l'évaluation après acceptation)
      
      
      const evaluationData = {
        identifiant_activite: formData.identifiantActivite,
        identifiant_eleve: formData.identifiantEleve,
        demande_evaluation: true,
      };
      const messaheUpdate = await crudsServiceFAPI.updateEvaluation(formData.identifiantEvaluation,evaluationData );

      // Générer l'activité finale
      //const messageCreate = await crudsServiceFAPI.createActiviteIA(formData.identifiantParcours, formData.identifiantEleve);
      // Optionnel: Afficher une notification
      // if (messageCreate) {
      //   showNotification(messageCreate);
      // } else {
      //   showNotification("Activité finale générée avec succès.");
      // }

      // Rafraîchir les évaluations après la mise à jour
      const updatedEvaluations = await crudsServiceFAPI.getActivitesEvaluationsByEleveId(userId);
      setAllEvaluations(updatedEvaluations);
    } catch (error) {
      console.error("Erreur lors de l'acceptation de l'évaluation :", error);
      showNotification("Erreur lors de l'acceptation de l'évaluation.");
    } finally {
      setSpinnerOpen(false); // Fermer le spinner
      handleClosePopup(); // Fermer la popup
    }
  };
 



  // useEffect pour récupérer les évaluations au montage du composant ou lors du changement de promotion
  useEffect(() => {
    console.log("glotte", userUid, userId)
    const fetchEvaluations = async () => {
      if (!selectedPromotion || !selectedPromotion.identifiant_promotion) {
        // Si selectedPromotion n'est pas défini, réinitialisez les évaluations
        setAllEvaluations([]);
        return;
      }

      setLoading(true);
      try {
        if(!userId){
        const evaluations = await crudsServiceFAPI.getAllEvaluationByPromotion(selectedPromotion.identifiant_promotion, selectedMatiere.identifiant_matiere);
        console.log("evaluations récupérées:", evaluations);
        setAllEvaluations(evaluations);
        }else{
          const evaluations = await crudsServiceFAPI.getAllEvaluationByEleveId(userId, selectedMatiere.identifiant_matiere);
        console.log("evaluations récupérées:", evaluations);
        setAllEvaluations(evaluations);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des evaluations :", error);
        showNotification("Erreur lors de la récupération des évaluations.");
        setAllEvaluations([]); // Assurez-vous de réinitialiser les évaluations en cas d'erreur
      } finally {
        setLoading(false);
      }
    };

    // Réinitialiser les évaluations avant de les recharger
    setAllEvaluations([]);
    fetchEvaluations();
  }, [selectedPromotion, selectedMatiere, userId]);

  // useEffect pour récupérer les élèves de la promotion
  useEffect(() => {
    const fetchElevesPromotion = async () => {
      if (!selectedPromotion || !selectedPromotion.identifiant_promotion) {
        setEleves([]);
        return;
      }

      setLoading(true);
      try {
        const elevesData = await crudsServiceFAPI.getElevesByPromotion(selectedPromotion.identifiant_promotion);
        console.log("eleves récupérées:", elevesData);
        setEleves(elevesData);
      } catch (error) {
        console.error("Erreur lors de la récupération des eleves :", error);
        showNotification("Erreur lors de la récupération des eleves.");
        setEleves([]);
      } finally {
        setLoading(false);
      }
    };

    fetchElevesPromotion();
  }, [selectedPromotion?.identifiant_promotion]); // Utilisez l'opérateur optionnel

  // useEffect pour récupérer les activités au montage du composant ou lors du changement de userId/promotion
  useEffect(() => {
    const fetchActivites = async () => {
      console.log("jacinte : ", userId, userUid);

      if (userId) {
        setLoading(true);
        try {
          const activities = await crudsServiceFAPI.getActivitesEvaluationsByEleveId(userId);
          console.log("jacinte Activités récupérées :", activities);
          setRawCardData(activities);
        } catch (error) {
          console.error("Erreur lors de la récupération des activités :", error);
          showNotification("Erreur lors de la récupération des activités.");
          setRawCardData([]);
        } finally {
          setLoading(false);
        }
      } else if (selectedPromotion && selectedPromotion.identifiant_promotion) {
        setLoading(true);
        try {
          const activities = await crudsServiceFAPI.getActivitesEvaluationsByPromotionId(selectedPromotion.identifiant_promotion);
          console.log("jacinte Activités récupérées promotion :", activities);
          setRawCardData(activities);
        } catch (error) {
          console.error("Erreur lors de la récupération des activités :", error);
          showNotification("Erreur lors de la récupération des activités.");
          setRawCardData([]);
        } finally {
          setLoading(false);
        }
      } else {
        // Si ni userId ni selectedPromotion ne sont définis
        setRawCardData([]);
      }
    };

    fetchActivites();
  }, [userId, selectedPromotion?.identifiant_promotion]); // Utilisez l'opérateur optionnel

  useEffect(() => {
    const fetchActivitesIsEval = async () => {
      console.log("jacinte : ", userId, userUid);

      if (selectedPromotion && selectedPromotion.identifiant_promotion) {
        setLoading(true);
        try {
          const activitiesis_eval = await crudsServiceFAPI.getActivitesIsEvalByPromotionId(selectedPromotion.identifiant_promotion, selectedMatiere.identifiant_matiere);
          console.log("isEval activites recup :", activitiesis_eval);
          setRawCardDataIsEval(activitiesis_eval);
        } catch (error) {
          console.error("Erreur lors de la récupération des activités :", error);
          showNotification("Erreur lors de la récupération des activités.");
          setRawCardDataIsEval([]);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchActivitesIsEval();
  }, [selectedPromotion?.identifiant_promotion, selectedMatiere]); // Ajout de la dépendance correcte


  useEffect(() => {
    const formatActivitiesIsEval = async () => {
      const timestamp = new Date().getTime(); // Ajout de timestamp ici
      console.log("rawCardDataIsEval.length : ", rawCardDataIsEval.length);

      const formattedActivities = await Promise.all(
        rawCardDataIsEval.map(async (activity) => {
          console.log("Traitement de l'activité eval :", activity);
          const imageUrl = `${process.env.REACT_APP_FAPI_URL}/files/activites/${activity.identifiant_parcours}.jpg`;
          const defaultImageUrl = `${process.env.REACT_APP_FAPI_URL}/files/activites/defaut.jpg?t=${timestamp}`;
          const imageExists = await checkImageExistence(imageUrl);

          return {
            titre: activity.titre_activite || "Titre non fourni",
            image: imageExists ? imageUrl : defaultImageUrl,
            description: activity.description_activite || "Description non fournie",
            details: activity.detail_activite || "Détails non fournis",
            evaluation: activity.evaluation_activite || "Évaluation non fournie",
            livrables: activity.livrables_activite || "Livrables non fournis",
            todo: activity.todo_activite || "Tâches non fournies",
            competences: activity.competences || [],
            identifiant_activite: activity.identifiant_activite,
            role: role,
            identifiant_parcours: activity.identifiant_parcours,
            titre_parcours: activity.titre_parcours,
            titre_activite: activity.titre_activite,
            timestamp_evaluation: activity.timestamp_evaluation,
            identifiant_utilisateur: activity.identifiant_utilisateur
          };
        })
      );

      console.log("Activités formatées isEval :", formattedActivities);
      setCardDataIsEval(formattedActivities);
    };

    if (rawCardDataIsEval.length > 0) {
      formatActivitiesIsEval();
    } else {
      console.log("Aucune activité à formater.");
      setCardDataIsEval([]); // Assurez-vous que cardDataIsEval est vide si rawCardDataIsEval est vide
    }
  }, [rawCardDataIsEval, role, selectedMatiere, userId]);

  // useEffect pour formater les activités une fois rawCardData mis à jour
  useEffect(() => {
    const formatActivities = async () => {
      const timestamp = new Date().getTime(); // Ajout de timestamp ici
      console.log("rawCardData.length : ", rawCardData.length);

      const formattedActivities = await Promise.all(
        rawCardData.map(async (activity) => {
          console.log("Traitement de l'activité :", activity);
          const imageUrl = `${process.env.REACT_APP_FAPI_URL}/files/activites/${activity.identifiant_parcours}.jpg`;
          const defaultImageUrl = `${process.env.REACT_APP_FAPI_URL}/files/activites/defaut.jpg?t=${timestamp}`;
          const imageExists = await checkImageExistence(imageUrl);

          return {
            titre_activite: activity.titre_activite || "Titre non fourni", // Changement de 'titre' à 'titre_activite'
            image: imageExists ? imageUrl : defaultImageUrl,
            description: activity.description_activite || "Description non fournie",
            details: activity.detail_activite || "Détails non fournis",
            evaluation: activity.evaluation_activite || "Évaluation non fournie",
            livrables: activity.livrables_activite || "Livrables non fournis",
            todo: activity.todo_activite || "Tâches non fournies",
            competences: activity.competences || [],
            identifiant_activite: activity.identifiant_activite,
            role: role,
            identifiant_parcours: activity.identifiant_parcours,
            denomination_parcours: activity.titre_parcours,
            titre: activity.titre_activite, // Ajout de 'titre' pour correspondre à ActiviteCard
            timestamp_evaluation: activity.timestamp_evaluation,
            identifiant_utilisateur: activity.identifiant_utilisateur
          };
        })
      );

      console.log("Activités formatées :", formattedActivities);
      setCardData(formattedActivities);
    };

    if (rawCardData.length > 0) {
      formatActivities();
    } else {
      console.log("Aucune activité à formater.");
      setCardData([]); // Assurez-vous que cardData est vide si rawCardData est vide
    }
  }, [rawCardData, role, selectedMatiere, userId]);

  // Fonction pour gérer l'expansion des cartes
  const handleExpandCard = (identifiant_activite) => {
    setExpandedCardId(expandedCardId === identifiant_activite ? null : identifiant_activite);
  };

  // Choisir le composant de carte basé sur le rôle de l'utilisateur
  const CardComponent = AllActivitesCard;
  const CardComponent_eval = AllEvaluationCard
  // Fonctions pour accepter et refuser une évaluation
  const acceptValidation = async (identifiant_eleve, identifiant_parcours, identifiant_evaluation) => {
    console.log("bouffe : ", identifiant_parcours);
    try {
      setSpinnerOpen(true); // Ouvrir le spinner

      // Mise à jour de l'évaluation (supposant que vous souhaitez supprimer l'évaluation après acceptation)
      //const messageDelete = await crudsServiceFAPI.deleteEvaluation(identifiant_evaluation);
     

      // Générer l'activité finale
      const messageCreate = await crudsServiceFAPI.createActiviteIA(identifiant_parcours, identifiant_evaluation);
      // Optionnel: Afficher une notification
      // if (messageCreate) {
      //   showNotification(messageCreate);
      // } else {
      //   showNotification("Activité finale générée avec succès.");
      // }

      // Rafraîchir les évaluations après la mise à jour
      const updatedEvaluations = await crudsServiceFAPI.getActivitesEvaluationsByEleveId(userId);
      setAllEvaluations(updatedEvaluations);
    } catch (error) {
      console.error("Erreur lors de l'acceptation de l'évaluation :", error);
      showNotification("Erreur lors de l'acceptation de l'évaluation.");
    } finally {
      setSpinnerOpen(false); // Fermer le spinner
    }
  };

  const refuseValidation = async (identifiant_eleve, identifiant_parcours, identifiant_evaluation) => {
    try {
      setSpinnerOpen(true); // Ouvrir le spinner

      const evaluationData = {
        identifiant_eleve,
        identifiant_parcours,
        demande_evaluation: false,
        timestamp: new Date().toISOString().split('.')[0].replace('T', ' ') // Formater en 'YYYY-MM-DD HH:MM:SS'
      };

      // Mise à jour de l'évaluation
      const messageUpdate = await crudsServiceFAPI.updateEvaluation(identifiant_evaluation, evaluationData);
      if (messageUpdate) {
        showNotification(messageUpdate);
      } else {
        showNotification("Évaluation refusée avec succès.");
      }

      // Rafraîchir les évaluations après la mise à jour
      const updatedEvaluations = await crudsServiceFAPI.getAllEvaluationByPromotion(selectedPromotion.identifiant_promotion);
      setAllEvaluations(updatedEvaluations);
    } catch (error) {
      console.error("Erreur lors du refus de l'évaluation :", error);
      showNotification("Erreur lors du refus de l'évaluation.");
    } finally {
      setSpinnerOpen(false); // Fermer le spinner
    }
  };

  // Fonction pour afficher les notifications
  const showNotification = (message) => {
    setNotification({ value: true, text: message });
    setTimeout(() => setNotification({ value: false, text: "" }), 3000);
  };

  // Définition des colonnes du DataTable
  const EvaluationsTableColumns = () => [
    // { Header: "Identifiant évaluation", accessor: "identifiant_evaluation", width: "10%" },
    { Header: "Identifiant élève", accessor: "identifiant_eleve", width: "15%" },
    { Header: "Titre parcours", accessor: "titre_parcours", width: "15%" },
    { Header: "Identifiant parcours", accessor: "identifiant_parcours", width: "15%" },
    { Header: "Titre activité", accessor: "titre_activite", width: "15%" },
    { Header: "Date", accessor: "timestamp", width: "15%" },
    {
      Header: "Statut de la demande",
      accessor: "demande_evaluation",
      width: "15%",
      Cell: ({ cell: { value } }) => (
        <MDTypography variant="button" color={value ? "success" : "warning"}>
          {value ? "Acceptée" : "En attente"}
        </MDTypography>
      ),
    },
    {
      Header: "Actions",
      Cell: ({ row }) => {
        const isAccepted = row.original.demande_evaluation;
        return (
          <MDBox display="flex" alignItems="center">
            {/* Bouton Accepter */}
            {ability.can("edit", "evaluations") && (
              <IconButton
                onClick={() => acceptValidation(row.original.identifiant_eleve, row.original.identifiant_parcours, row.original.identifiant_evaluation)}
                title="Accepter"
                disabled={isAccepted}
              >
                <AutoAwesomeIcon color={isAccepted === true ? "disabled" : "success"} />
              </IconButton>
            )}
            {/* Bouton Refuser */}
            {ability.can("delete", "evaluations") && (
              <IconButton
                onClick={() => refuseValidation(row.original.identifiant_eleve, row.original.identifiant_parcours, row.original.identifiant_evaluation)}
                title="Refuser"
                disabled={isAccepted}
              >
                <DoNotDisturbIcon color={isAccepted ? "disabled" : "error"} />
              </IconButton>
            )}
            {/* Bouton AutoAwesome */}
            {ability.can("assign", "evaluations") && (
              <IconButton
                title="Attribuer une activité"
                onClick={() => handleOpenPopup(row.original)}
                disabled={isAccepted}
              >
                <AddLinkIcon color={isAccepted ? "disabled" : "error"}  />
              </IconButton>
            )}
          </MDBox>
        );
      },
    },
  ];
  

  return (
    <DashboardLayout>
      <DashboardNavbar />

       {/* Section Image et Infos */}
       <MDBox py={3}>
        <Card sx={{ overflow: "visible" }}>
          <MDBox p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} xl={5}>
                <ValidationImage />
              </Grid>
              <Grid item xs={12} lg={5} sx={{ mx: "auto" }}>
                <ValidationsInfo />
              </Grid>
              {/* Suppression des menus déroulants pour les référentiels et compétences */}
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
      
      {/* Section des Demandes d'Évaluation */}
      {ability.can("view", "validations") && (
        <MDBox mb={3}>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h4" fontWeight="medium" color="info">
                Demandes d'évaluation
              </MDTypography>
              <DataTable 
                table={{ 
                  columns: EvaluationsTableColumns(), 
                  rows: allEvaluations 
                }} 
                entriesPerPage={10}
                showTotalEntries
                noEndBorder
              />
            </MDBox>
          </Card>
        </MDBox>
      )}
      
     

      <MDBox py={3}>
        <Card sx={{ overflow: "visible" }}>
          <MDBox p={3}>
            <Grid container spacing={3}>
              <MDTypography variant="h5" fontWeight="bold">
                Evaluations disponibles
              </MDTypography>
              
              {/* Affichage basé sur le statut de chargement */}
      {loading ? (
        <MDTypography variant="h6" align="center">
          Chargement des activités...
        </MDTypography>
      ) : (
        <>
          {/* Section pour les activités seulement si cardData contient des activités */}
          {cardDataIsEval.length > 0 ? (
            <MDBox flex="1" mt={2}>
              <Grid container spacing={3}>
                {cardDataIsEval.map((card) => (
                  <Grid item xs={12} sm={6} md={expandedCardId === card.identifiant_activite ? 12 : 4} key={card.identifiant_activite}>
                    <MDBox mt={3}>
                      <CardComponent_eval
                        title={card.titre}
                        image={card.image}
                        description={card.description}
                        details={card.details}
                        evaluation={card.evaluation}
                        livrables={card.livrables}
                        todo={card.todo}
                        onExpand={() => handleExpandCard(card.identifiant_activite)}
                        expanded={expandedCardId === card.identifiant_activite}
                        role={card.role}
                        identifiant_activite={card.identifiant_activite}
                        identifiant_parcours={card.identifiant_parcours}
                        denomination_parcours={card.titre_parcours}
                        titre_activite={card.titre_activite}
                        timestamp_evaluation={card.timestamp_evaluation}
                        identifiant_utilisateur={card.identifiant_utilisateur}
                        idMatiere={selectedMatiere} // Ajout de la prop idMatiere
                        padlet_url={card.padlet_url} // Ajout de padlet_url si disponible
                      />
                    </MDBox>
                  </Grid>
                ))}
              </Grid>
            </MDBox>
          ) : (
            // Optionnel : Afficher un message lorsque aucune activité n'est disponible
            <MDTypography variant="h6" align="center" mt={4}>
              Aucune activité disponible.
            </MDTypography>
          )}
        </>
      )}
            </Grid>
          </MDBox>
        </Card>
      </MDBox>

      <MDBox py={3}>
        <Card sx={{ overflow: "visible" }}>
          <MDBox p={3}>
            <Grid container spacing={3}>
              <MDTypography variant="h5" fontWeight="bold">
                Evaluations assignées
              </MDTypography>
              
              {/* Affichage basé sur le statut de chargement */}
      {loading ? (
        <MDTypography variant="h6" align="center">
          Chargement des activités...
        </MDTypography>
      ) : (
        <>
          {/* Section pour les activités seulement si cardData contient des activités */}
          {cardData.length > 0 ? (
            <MDBox flex="1" mt={2}>
              <Grid container spacing={3}>
                {cardData.map((card) => (
                  <Grid item xs={12} sm={6} md={expandedCardId === card.identifiant_activite ? 12 : 4} key={card.identifiant_activite}>
                    <MDBox mt={3}>
                      <CardComponent
                        title={card.titre_activite} // Assurez-vous que 'titre' est correctement passé
                        image={card.image}
                        description={card.description}
                        details={card.details}
                        evaluation={card.evaluation}
                        livrables={card.livrables}
                        todo={card.todo}
                        onExpand={() => handleExpandCard(card.identifiant_activite)}
                        expanded={expandedCardId === card.identifiant_activite}
                        role={card.role}
                        identifiant_activite={card.identifiant_activite}
                        identifiant_parcours={card.identifiant_parcours}
                        denomination_parcours={card.titre_parcours}
                        titre_activite={card.titre_activite}
                        timestamp_evaluation={card.timestamp_evaluation}
                        identifiant_utilisateur={card.identifiant_utilisateur}
                        idMatiere={selectedMatiere} // Ajout de la prop idMatiere
                        padlet_url={card.padlet_url} // Ajout de padlet_url si disponible
                      />
                    </MDBox>
                  </Grid>
                ))}
              </Grid>
            </MDBox>
          ) : (
            // Optionnel : Afficher un message lorsque aucune activité n'est disponible
            <MDTypography variant="h6" align="center" mt={4}>
              Aucune activité disponible.
            </MDTypography>
          )}
        </>
      )}
            </Grid>
          </MDBox>
        </Card>
      </MDBox>

      {/* Spinner de Chargement */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={spinnerOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Notification */}
      {notification.value && (
        <MDAlert color="info" my="20px">
          <MDTypography variant="body2" color="white">
            {notification.text}
          </MDTypography>
        </MDAlert>
      )}

{popupOpen && (
  <Dialog open={popupOpen} onClose={handleClosePopup}>
    <DialogTitle>Attribuer une activité</DialogTitle>
    <DialogContent>
      <TextField
        margin="dense"
        label="Identifiant Évaluation"
        fullWidth
        value={formData.identifiantEvaluation || ""}
        onChange={(e) => setFormData({ ...formData, identifiantEvaluation: e.target.value })}
        disabled // Désactivé si non modifiable
      />
      <TextField
        margin="dense"
        label="Identifiant Parcours"
        fullWidth
        value={formData.identifiantParcours || ""}
        onChange={(e) => setFormData({ ...formData, identifiantParcours: e.target.value })}
      />
      <TextField
        margin="dense"
        label="Identifiant Élève"
        fullWidth
        value={formData.identifiantEleve || ""}
        onChange={(e) => setFormData({ ...formData, identifiantEleve: e.target.value })}
      />
      <FormControl fullWidth margin="dense">
        <InputLabel>Activité</InputLabel>
        <Select
          value={formData.identifiantActivite || ""}
          onChange={(e) => setFormData({ ...formData, identifiantActivite: e.target.value })}
        >
          {cardDataIsEval.map((card) => (
            <MenuItem key={card.identifiant_activite} value={card.identifiant_activite}>
              {card.titre_activite}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClosePopup} color="secondary">Annuler</Button>
      <Button onClick={handleValidation} color="primary">Valider</Button>
    </DialogActions>
  </Dialog>
)}

      <Footer />
    </DashboardLayout>
  );
}

export default Evaluations;
