import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import FormField from "layouts/parcours/parcours-gestion/activite-nouveau/components/FormField";
import MDInput from "components/MDInput"; // Importation de MDInput pour le champ de fichier
import crudsServiceFAPI from "services/cruds-serviceFAPI";
import { useNavigate } from "react-router-dom"; // Importation de useNavigate
import MDButton from "components/MDButton";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import useStore from "services/store";
import DeleteIcon from "@mui/icons-material/Delete"; // Import de l’icône de suppression
import IconButton from "@mui/material/IconButton"; // Import de IconButton pour le bouton de suppression
function ActiviteInfo({ identifiant_activite, parcoursId, is_eval }) {


  const {
    selectedEtab,
    setSelectedEtab,
    selectedSection,
    setSelectedSection,
    selectedPromotion,
    setSelectedPromotion,
    selectedMatiere,
    setSelectedMatiere,
    userId,
    setUserId,
    userIdentite,
    setUserIdentite,
  } = useStore((state) => ({
    selectedEtab: state.selectedEtab,
    setSelectedEtab: state.setSelectedEtab,
    selectedSection: state.selectedSection,
    setSelectedSection: state.setSelectedSection,
    selectedPromotion: state.selectedPromotion,
    setSelectedPromotion: state.setSelectedPromotion,
    selectedMatiere: state.selectedMatiere,
    setSelectedMatiere: state.setSelectedMatiere,
    userId: state.userId,
    setUserId: state.setUserId,
    userIdentite: state.userIdentite,
    setUserIdentite: state.setUserIdentite,
    selectedReferentiel: state.selectedReferentiel,
    setSelectedReferentiel: state.setSelectedReferentiel,
  }));

  console.log("survivor : ", selectedPromotion, is_eval)
  const [denominationActivite, setDenominationActivite] = useState("");
  const [descriptionActivite, setDescriptionActivite] = useState("");
  const [detailsActivite, setDetailsActivite] = useState("");
  const [evaluationActivite, setEvaluationActivite] = useState("");
  const [livrablesActivite, setLivrablesActivite] = useState("");
  const [todoActivite, setTodoActivite] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [idRessource, setIdRessource] = useState("");
  const [resourceList, setResourceList] = useState([]); // Liste des ressources téléversées
  const [referentiels, setReferentiels] = useState([]); // Liste des référentiels
  const [openPopup, setOpenPopup] = useState(false); // État pour ouvrir/fermer la popup
  const [selectedReferentiel, setSelectedReferentiel] = useState({ id: "", identifiant_activite: "" });
  const [selectedCompetence, setSelectedCompetence] = useState(""); // État pour la compétence sélectionnée
  const [competences, setCompetences] = useState([]); // Liste des compétences associées au référentiel
  const [niveauVise, setNiveauVise] = useState(0); // Niveau visé pour la compétence
  const [niveauSelectionne, setNiveauSelectionne] = useState(1); // Niveau sélectionné par l'utilisateur
  const [competencesAjoutees, setCompetencesAjoutees] = useState([]); // Compétences temporairement ajoutées
  const [padletUrl, setPadletUrl] = useState(""); // Nouvel état pour l'URL Padlet

  const [Competences, SetCompetences] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchActiviteDetails = async () => {
      if (identifiant_activite) {
        try {
          const competences = await crudsServiceFAPI.getCompetencesByActivite(identifiant_activite)
          console.log("wali : ", competences)
          SetCompetences(competences)
          const activiteData = await crudsServiceFAPI.getActiviteByDenomination(identifiant_activite);
          console.log("papa : ", identifiant_activite);
          setDenominationActivite(activiteData.titre_activite);
          setDescriptionActivite(activiteData.description_activite);
          setDetailsActivite(activiteData.detail_activite);
          setEvaluationActivite(activiteData.evaluation_activite);
          setLivrablesActivite(activiteData.livrables_activite);
          setTodoActivite(activiteData.todo_activite);
          setPadletUrl(activiteData.padlet_url || ""); // Initialiser le padlet_url
        } catch (error) {
          console.error("Erreur lors de la récupération des détails de l'activité :", error);
        }
      }
    };

    fetchActiviteDetails();
  }, [identifiant_activite]);

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const [errorMessage, setErrorMessage] = useState("");


  const openReferentielPopup = async () => {
    if (!denominationActivite) {
      console.error("L'activité n'a pas encore été récupérée");
      return;
    }
  
    try {
      // Récupérer les compétences associées au référentiel sélectionné
      const response = await crudsServiceFAPI.getReferentielsParPromotion(selectedPromotion.identifiant_promotion);
      console.log("crainteRéférentiels récupérés : ", response[0].identifiant_referentiel);
      setSelectedReferentiel(response[0].identifiant_referentiel)
      
        const competences = await crudsServiceFAPI.getCompetencesByReferentiel(response[0].identifiant_referentiel);
        console.log("crainteCompétences récupérées : ", response);
        setCompetences(competences); // Mettre à jour les compétences associées
      
      setOpenPopup(true); // Ouvrir la popup après avoir récupéré les données
    } catch (error) {
      console.error("Erreur lors de la récupération des compétences :", error);
    }
  };

  const handleSelectReferentiel = async (event) => {
    const referentielId = event.target.value;
    setSelectedReferentiel(referentielId);

    try {
      // Récupérer les compétences associées au référentiel sélectionné
      if (referentielId) {
        const response = await crudsServiceFAPI.getCompetencesByReferentiel(referentielId);
        console.log("Compétences récupérées : ", response);
        setCompetences(response); // Mettre à jour les compétences associées
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des compétences :", error);
    }
  };

  // Lors de la sélection d'une compétence
  const handleSelectCompetence = (event) => {
    console.log("decibel : ", event);
    const competenceId = event.target.value;
    console.log("competenceId : ", competenceId);
    setSelectedCompetence(competenceId); // Mise à jour de l'état avec la compétence sélectionnée

    // Rechercher la compétence dans la liste des compétences récupérées
    const competence = competences.find((c) => c.identifiant_competence === competenceId);

    if (competence) {
      // Mettre à jour les niveaux et points visés associés à la compétence
      setNiveauVise(competence.niveau_competence_visee_referentiel); // Définir le niveau visé
      setNiveauSelectionne(1); // Réinitialiser le niveau sélectionné à 1
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setSelectedFileName(file.name); // Stocker le nom du fichier sélectionné
  };

  const handleUploadResource = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      try {
        const response = await crudsServiceFAPI.addResource(formData);
        console.log("trophée ID : ", response);
        setIdRessource(response.identifiant_ressource);
        const resourceUrl = `${process.env.REACT_APP_FAPI_URL}/files/ressources/${selectedFile.name}`;

        // Ajouter la ressource à la liste des ressources téléversées
        setResourceList((prevList) => [
          ...prevList,
          { name: selectedFileName, url: resourceUrl },
        ]);

        // Réinitialiser le champ de fichier après téléversement
        setSelectedFile(null);
        setSelectedFileName("");
      } catch (error) {
        console.error("Erreur lors du téléversement de la ressource :", error);
      }
    }
  };

  const handleSave = async () => {
    if (competencesAjoutees.length === 0) {
      setErrorMessage("Vous devez ajouter au moins une compétence avant de sauvegarder l'activité.");
      return;
    }
    try {
      const activiteData = {
        titre_activite: denominationActivite,
        description_activite: descriptionActivite,
        detail_activite: detailsActivite,
        evaluation_activite: evaluationActivite,
        livrables_activite: livrablesActivite,
        todo_activite: todoActivite,
        identifiant_parcours: parseInt(parcoursId),
        padlet_url: padletUrl, // Inclure l'URL du Padlet
        is_evaluation: is_eval
      };
      console.log("respect : ", activiteData, identifiant_activite);
      let response;
      if (identifiant_activite) {
        response = await crudsServiceFAPI.updateActivite(parseInt(identifiant_activite), activiteData);
      } else {
        response = await crudsServiceFAPI.createActivite(activiteData);
        console.log("groupette : ", response)
        identifiant_activite = response.identifiant_activite; // Récupérer l'identifiant de l'activité nouvellement créée
      }

      // Enregistrer les compétences ajoutées

for (const competence of competencesAjoutees) {
  const payloadCompetence = {
    identifiant_activite: identifiant_activite,
    identifiant_competence: competence.numero_competence, // Correction ici
    niveau_vise_activite: competence.niveau_vise_activite,
  };
  console.log("passer : ", payloadCompetence)
  await crudsServiceFAPI.addActiviteCompetence(payloadCompetence); // Envoi à la base de données
}



      // Associer chaque ressource téléversée à l'activité
      for (let resource of resourceList) {
        console.log("trophée", identifiant_activite, idRessource);
        await crudsServiceFAPI.associateResourceToActivity(identifiant_activite, idRessource);
      }

      navigate(`/parcours/parcours-espace/parcours-page/${parcoursId}`);
    } catch (error) {
      console.error("Erreur lors de l'enregistrement de l'activité et des ressources :", error);
    }
  };

  const pointsParNiveau = {
    1: 25,
    2: 50,
    3: 100,
    4: 150
  }[niveauSelectionne] || 0;

  const handleDeleteResource = (resourceName) => {
    setResourceList((prevList) => prevList.filter((resource) => resource.name !== resourceName));
  };
  
  // Fonction pour supprimer une compétence de la liste
  const handleDeleteCompetence = (competenceId) => {
    setCompetencesAjoutees((prevCompetences) =>
      prevCompetences.filter((competence) => competence.numero_competence !== competenceId)
    );
  };
  
  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Informations de l'activité</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormField
                type="text"
                label="Dénomination de l'activité"
                value={denominationActivite}
                onChange={(e) => setDenominationActivite(e.target.value)}
              />
            </Grid>
          </Grid>
        </MDBox>
        {/* Bouton pour ouvrir la popup des référentiels */}
        <MDBox mt={2}>
          <MDButton variant="contained" color="primary" onClick={openReferentielPopup}>
            Ajouter une compétence
          </MDButton>
          {/* Compétences ajoutées temporairement */}
          <MDBox mt={2}>
          <MDTypography variant="h6">Compétences de l'activité :</MDTypography>
          {Competences.length > 0 ? (
            <ul>
              {Competences.map((competence, index) => (
               
                <li key={index}>
                  {competence.denomination_competence} - Points visés : {competence.points_competence_vises} - Niveau visé : {competence.niveau_vise_activite}
                 
          
                </li>
                
            
              ))}
            </ul>
          ) : (
            <MDTypography variant="body2" color="text">
              Aucune compétence dans l'activité
            </MDTypography>
          )}
        </MDBox>
        <MDBox mt={2}>
          <MDTypography variant="h6">Compétences ajoutées :</MDTypography>
          {competencesAjoutees.length > 0 ? (
            <ul>
              {competencesAjoutees.map((competence, index) => (
               
                <li key={index}>
                  Compétence {competence.numero_competence} - Points visés : {competence.points_competence_vises} - Niveau visé : {competence.niveau_vise_activite}
                  <IconButton onClick={() => handleDeleteCompetence(competence.numero_competence)} color="error">
                <DeleteIcon />
              </IconButton>
                </li>
                
            
              ))}
            </ul>
          ) : (
            <MDTypography variant="body2" color="text">
              Aucune compétence ajoutée pour l'instant.
            </MDTypography>
          )}
        </MDBox>
        </MDBox>

        {/* Bloc pour la description */}
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Description de l'activité&nbsp;&nbsp;
                  <MDTypography variant="caption" fontWeight="regular" color="text">
                    (facultatif)
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDEditor value={descriptionActivite} onChange={setDescriptionActivite} />
            </Grid>
          </Grid>
        </MDBox>

        {/* Détails de l'activité */}
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Détails de l'activité&nbsp;&nbsp;
                </MDTypography>
              </MDBox>
              <MDEditor value={detailsActivite} onChange={setDetailsActivite} />
            </Grid>
          </Grid>
        </MDBox>

        {/* Tâches à réaliser (TODO) */}
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Tâches à réaliser (TODO)&nbsp;&nbsp;
                </MDTypography>
              </MDBox>
              <MDEditor value={todoActivite} onChange={setTodoActivite} />
            </Grid>
          </Grid>
        </MDBox>

        {/* Livrables activité */}
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Livrables activité&nbsp;&nbsp;
                </MDTypography>
              </MDBox>
              <MDEditor value={livrablesActivite} onChange={setLivrablesActivite} />
            </Grid>
          </Grid>
        </MDBox>

        {/* Évaluation activité */}
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Évaluation activité&nbsp;&nbsp;
                </MDTypography>
              </MDBox>
              <MDEditor value={evaluationActivite} onChange={setEvaluationActivite} />
            </Grid>
          </Grid>
        </MDBox>

        {/* URL Padlet */}
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  URL Padlet&nbsp;&nbsp;
                  <MDTypography variant="caption" fontWeight="regular" color="text">
                    (facultatif)
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <FormField
                type="text"
                label="URL Padlet"
                value={padletUrl}
                onChange={(e) => setPadletUrl(e.target.value)}
                placeholder="Entrez l'URL du Padlet"
              />
            </Grid>
          </Grid>

          {/* Optionnel : Affichage de l'embed Padlet si l'URL est présente */}
          {padletUrl && (
            <MDBox mt={2}>
              <div
                className="padlet-embed"
                style={{
                  border: "1px solid rgba(0,0,0,0.1)",
                  borderRadius: "2px",
                  boxSizing: "border-box",
                  overflow: "hidden",
                  position: "relative",
                  width: "100%",
                  background: "#F4F4F4",
                }}
              >
                <p style={{ padding: 0, margin: 0 }}>
                  <iframe
                    src={padletUrl}
                    frameBorder="0"
                    allow="camera;microphone;geolocation"
                    style={{
                      width: "100%",
                      height: "608px",
                      display: "block",
                      padding: 0,
                      margin: 0,
                    }}
                    title="Padlet Embedded"
                  ></iframe>
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    margin: 0,
                    height: "28px",
                  }}
                >
                  <a
                    href="https://padlet.com?ref=embed"
                    style={{
                      display: "block",
                      flexGrow: 0,
                      margin: 0,
                      border: "none",
                      padding: 0,
                      textDecoration: "none",
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src="https://padlet.net/embeds/made_with_padlet_2022.png"
                        width="114"
                        height="28"
                        style={{
                          padding: 0,
                          margin: 0,
                          background: "0 0",
                          border: "none",
                          boxShadow: "none",
                        }}
                        alt="Made with Padlet"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </MDBox>
          )}
        </MDBox>

        {/* Ressources */}
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Ressources&nbsp;&nbsp;
                </MDTypography>
                <MDButton
                  variant="contained"
                  color="success"
                  component="label"
                  htmlFor="upload-resource"
                  sx={{ ml: 2 }}
                >
                  Ajouter une ressource
                  <MDInput
                    type="file"
                    id="upload-resource"
                    name="attachment"
                    accept="*/*"
                    onChange={handleFileChange}
                    sx={{ display: "none" }}
                  />
                </MDButton>
              </MDBox>
              {selectedFileName && (
                <MDBox mt={2}>
                  <MDTypography variant="body2" color="text">
                    Fichier sélectionné : {selectedFileName}
                  </MDTypography>
                </MDBox>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={handleUploadResource}
                sx={{ mt: 2 }}
                disabled={!selectedFile}
              >
                Téléverser la ressource
              </Button>
              {resourceList.length > 0 && (
                <MDBox mt={3}>
                  <MDTypography variant="h6">Ressources téléversées :</MDTypography>
                  <ul>
                    {resourceList.map((resource, index) => (
                      <li key={index}>
                        <a href={resource.url} target="_blank" rel="noopener noreferrer">
                          {resource.name}
                        </a>
                        <IconButton onClick={() => handleDeleteResource(resource.name)} color="error">
                <DeleteIcon />
              </IconButton>
                      </li>
                    ))}
                  </ul>
                </MDBox>
              )}
            </Grid>
          </Grid>
        </MDBox>

        

        <MDBox mt={2}>
  <Button variant="contained" color="primary" onClick={handleSave} disabled={competencesAjoutees.length === 0}>
    Enregistrer
  </Button>
  {errorMessage && (
    <MDTypography variant="body2" color="error">
      {errorMessage}
    </MDTypography>
  )}
</MDBox>

        <Dialog open={openPopup} onClose={handleClosePopup}>
          <DialogTitle>
            Référentiel pour l'activité : {denominationActivite || 'Non définie'}
          </DialogTitle>
          <DialogContent>
            <MDBox mt={2}>
              <MDTypography variant="subtitle1">Référentiel sélectionné :</MDTypography>
              <MDInput
                fullWidth
                value={selectedReferentiel}
                disabled
                style={{ marginTop: "8px" }}
              />
            </MDBox>

            {/* Affichage des compétences si le référentiel est défini */}
            {selectedReferentiel && (
              <>
                <MDTypography variant="subtitle1" style={{ marginTop: "16px" }}>
                  Sélectionner une compétence
                </MDTypography>
                <Select
                  fullWidth
                  value={selectedCompetence}
                  onChange={handleSelectCompetence}
                  style={{ marginTop: "16px" }}
                >
                  {competences.map((competence) => (
                    <MenuItem
                      key={competence.identifiant_competence}
                      value={competence.identifiant_competence}
                    >
                      {competence.denomination_competence}
                    </MenuItem>
                  ))}
                </Select>

                {/* Affichage conditionnel pour les niveaux et points visés après sélection d'une compétence */}
                {selectedCompetence && (
  <>
    <MDBox mt={2}>
      <MDTypography variant="body2">
        Niveau visé dans le référentiel :{" "}
        {competences.find(
          (competence) => competence.identifiant_competence === selectedCompetence
        )?.niveau_competence_visee_referentiel}
      </MDTypography>

      <MDTypography variant="body2" style={{ marginTop: "16px" }}>
        Choisir un niveau visé dans cette activité :
      </MDTypography>
      <Select
        fullWidth
        value={niveauSelectionne}
        onChange={(e) => setNiveauSelectionne(e.target.value)} // Gérer la sélection du niveau
        style={{ marginTop: "16px" }}
      >
        {[...Array(niveauVise).keys()].map((i) => (
          <MenuItem key={i + 1} value={i + 1}>
            {i + 1}
          </MenuItem>
        ))}
      </Select>
    </MDBox>

    <MDTypography variant="subtitle1" style={{ marginTop: "16px" }}>
      Points à remporter dans ce niveau :
    </MDTypography>
    <MDBox mt={1}>
      {niveauSelectionne && (
        <>
          <MDTypography variant="body2">Non conforme : 0</MDTypography>
          <MDTypography variant="body2">
            Partiellement conforme : {pointsParNiveau * 0.5}
          </MDTypography>
          <MDTypography variant="body2">
            Parfaitement conforme : {pointsParNiveau}
          </MDTypography>
          <MDTypography variant="body2">
            Excellence : {pointsParNiveau * 1.2}
          </MDTypography>
        </>
      )}
    </MDBox>
  </>
)}

              </>
            )}
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClosePopup} color="primary">
              Annuler
            </Button>
            <Button
              onClick={() => {
                if (!denominationActivite || !selectedCompetence) {
                  console.error("Tous les champs de la compétence doivent être remplis");
                  return;
                }

                const competenceAjoutee = {
                  numero_competence: selectedCompetence,
                  niveau_vise_activite: niveauSelectionne,
                };

                setCompetencesAjoutees((prevCompetences) => [...prevCompetences, competenceAjoutee]);
                setErrorMessage("");
                handleClosePopup();
              }}
              color="primary"
            >
              Valider
            </Button>
          </DialogActions>
        </Dialog>

        {/* Affichage de la liste des ressources */}
        <MDBox mt={4}>
          <MDTypography variant="h6">Liste des ressources :</MDTypography>
          {resourceList.length > 0 ? (
            <ul>
              {resourceList.map((resource, index) => (
                <li key={index}>
                  <a href={resource.url} target="_blank" rel="noopener noreferrer">
                    {resource.name}
                  </a>
                </li>
              ))}
            </ul>
          ) : (
            <MDTypography variant="body2" color="text">
              Aucune ressource téléversée pour l'instant.
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ActiviteInfo;
