import PropTypes from "prop-types"; // Importer PropTypes pour valider les props
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card"; // Importer le composant Card
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function ValidationsInfo({ children }) {
  return (
    <MDBox>
      <MDBox mb={1}>
        <MDTypography variant="h3" fontWeight="bold">
          Espace évaluation
        </MDTypography>
      </MDBox>

      {/* <MDBox mt={3} mb={1} ml={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          Description

          
        </MDTypography>
      </MDBox> */}

      <Card sx={{ p: 2, mb: 2 }}>
        <MDTypography
          variant="body2"
          color="text"
          fontWeight="regular"
          component="div"
          dangerouslySetInnerHTML={{ __html: "Cet espace permet de consulter les évaluations de parcours" }}
        />
      </Card>

      {/* Affichage des children si présents */}
      {children && (
        <MDBox mt={3}>
          {children}
        </MDBox>
      )}
    </MDBox>
  );
}

// Valider que `children` est une prop de type React node et n'est pas requis
ValidationsInfo.propTypes = {
  children: PropTypes.node, // facultatif
};

export default ValidationsInfo;
